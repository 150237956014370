<template>
  <div class="container">
    <edit-header
      :error.sync="error"
      :errorMessage="errorMessage"
      :invalid.sync="invalid"
      :invalidMessage="invalidMessage"
      :submitted.sync="submitted"
      :isNew="isNew"
      entity="grn"/>
    <form
      v-disable-all="!can(uiPermissions.MANUAL_GRN_CREATE)"
      ref="form"
      data-test="form-manual-grn"
      class="validation"
      novalidate>
      <div class="row">

        <div class="col">
          <div data-test="card-po-filter" class="card">
            <div class="card-header">
              <h3 class="card-title">Goods Return Note</h3>
            </div>
            <div class="card-body">
              <!-- #region Supplier Dropdown  -->
              <div class="form-group row d-flex supplier-group">
                <label class="col-sm-5 col-form-label form-label text-right">Supplier</label>
                <div class="col-sm-4">
                  <autocomplete
                    v-model="$v.grn.supplier.$model"
                    :list.sync="suppliers"
                    :nextPage="nextSuppliers"
                    :class="{ 'is-invalid': $v.grn.supplier.$error }"
                    label="name"
                    entity="supplier"
                    data-test="txt-supplier"
                    placeholder="Select suppliers"
                    @input="onSupplierChange"/>
                  <template v-if="$v.grn.supplier.$dirty">
                    <div v-if="!$v.grn.supplier.required" class="invalid-feedback d-block">Supplier is required</div>
                  </template>
                </div>
              </div>
              <!-- #endregion End Supplier Dropdown  -->

              <!-- #region PO Number Input  -->
              <div class="form-group row d-flex date-group">
                <label class="col-sm-5 col-form-label form-label text-right">PO Number</label>
                <div class="col-sm-4">
                  <autocomplete
                    v-model="$v.grn.purchaseOrder.$model"
                    :list.sync="purchaseOrders"
                    :nextPage="nextPurchaseOrders"
                    :disabled="this.$v.grn.supplier.$invalid"
                    class="reduce-ul-height"
                    :class="{ 'is-invalid': $v.grn.purchaseOrder.$error }"
                    label="id"
                    entity="purchase_order"
                    data-test="txt-po-number"
                    placeholder="Select a PO Number"
                    @input="onPurchaseOrderChange"></autocomplete>

                  <template v-if="$v.grn.purchaseOrder.$dirty">
                    <div v-if="!$v.grn.purchaseOrder.required" class="invalid-feedback d-block">
                      PO Number is required
                    </div>
                  </template>
                </div>
              </div>
              <!-- #endregion PO Number Input  -->

              <!-- #region GRN Items Table -->
              <div class="table-responsive">
                <table data-test="table-articles" class="table table-hover table-outline table-vcenter card-table">
                  <thead>
                    <tr class="thead-light">
                      <th class="text-center">Article Id</th>
                      <th class="text-center">Article Name</th>
                      <th class="text-center">Quantity</th>
                      <th class="text-center">Return Reason</th>
                      <th class="w-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in $v.grn.items.$model" :key="index">
                      <td class="text-center">{{item.article.id}}</td>
                      <td class="text-center">{{item.article.name}}</td>
                      <td>
                        <input
                          v-model.number="item.quantity"
                          step="0.01"
                          type="number"
                          class="form-control">
                      </td>
                      <td>
                        <select v-model="item.reason" class="form-control custom-select">
                          <option :value="null" disabled>Select reason</option>
                          <option
                            v-for="reason in GRNReasons"
                            :key="reason"
                            :value="reason">
                            {{reason}}
                          </option>
                        </select>
                      </td>
                      <td>
                        <i
                          data-test="icon-delete"
                          class="fe fe-trash delete-icon"
                          @click="deleteGRNItem(index)">
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- #endregion GRN Items Table -->
            </div>
          </div>
          <div data-test="card-add-grn" class="card">
            <div class="card-body">
              <div class="row">
                <div v-if="canAddGRNItems" class="col-12">
                  <dimmer :active="loading">
                    <button
                      type="button"
                      data-test="btn-add-grn-item"
                      class="btn"
                      @click="() => showGRNItemForm = true">
                      Add GRN Item
                    </button>
                    <button
                      v-if="$v.grn.items.$model && $v.grn.items.$model.length"
                      type="button"
                      data-test="btn-create-grn"
                      class="btn btn-primary ml-5"
                      :disabled="loading"
                      @click="validateForSubmit">
                      Create GRN
                    </button>
                  </dimmer>
                </div>
                <div v-else>
                  <p class="ml-4 font-italic">Please select the supplier and PO Number to add GRN Items</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <b-modal
      v-model="showGRNItemForm"
      centered
      title="Add GRN Item"
      size="lg"
      hide-footer>
      <template slot="modal-header-close"><wbr/></template>
      <GRN-item-form :articles="articles" @submit="value => addNewGRNItem(value)"/>

    </b-modal>

    <b-modal
      v-model="showSubmitDialog"
      title="Submit GRN"
      centered
      @ok="submit">
      <template slot="modal-header-close"><wbr/></template>
      <p class="lead text-center">
        You cannot undo this.
        Are you sure you want to submit?
      </p>
    </b-modal>
  </div>
</template>

<script>

import {required} from 'vuelidate/lib/validators';
import GRNReasons from '@/assets/enums/GRNReasons';
import edit from '@/mixins/edit';
import EditHeader from '@/components/EditHeader';
import {grn, suppliers, purchaseOrders} from '@/services';
import GRNItemForm from './forms/GRNItemForm.vue';
import Autocomplete from '@/components/Autocomplete.vue';

export default {
  components: {
    GRNItemForm,
    EditHeader,
    Autocomplete,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      GRNReasons,
      route: '/operations/manual-grn',

      grn: {
        supplier: null,
        purchaseOrder: '',
        items: [],
      },
      articles: [],
      suppliers: [],
      purchaseOrders: [],
      showGRNItemForm: false,
      showSubmitDialog: false,

      prevSupplier: null,
      prevPurchaseOrder: null,
    };
  },
  computed: {
    canAddGRNItems() {
      return !this.$v.grn.supplier.$invalid && !this.$v.grn.purchaseOrder.$invalid;
    },
  },
  async created() {
    // Ability to create GRNs only. No edit
    if (!this.isNew) {
      this.$router.replace({path: '/404'});
    }

    this.suppliers = await suppliers.getByParameters({column: 'id', direction: 'asc'});
  },
  methods: {
    async submitData() {
      const payload = this.transformForSubmit();
      try {
        const response = await grn.save(payload);
        return response;
      }
      catch (error) {
        this.errorMessage = error.response.data.message;
        throw error;
      }
    },
    transformForSubmit() {
      return {
        supplier_id: this.$v.grn.supplier.$model?.id,
        purchase_order_id: this.$v.grn.purchaseOrder.$model?.id,
        items: this.$v.grn.items.$model.map(item => ({
          article_id: item.article.id,
          quantity: item.quantity,
          reason: item.reason,
        })),
      };
    },
    validate() {
      this.$v.grn.$touch();
      return !this.$v.grn.$invalid;
    },
    validateForSubmit() {
      const form = this.$refs.form;
      const valid = this.validate();
      const quantityNotZero = this.$v.grn.items.$model.every(item => item.quantity > 0);

      if (valid && form.checkValidity() && quantityNotZero) {
        this.invalid = false;
        this.showSubmitDialog = true;
      }
      else {
        this.invalidMessage = !quantityNotZero ? 'Make sure that the quantity values are correct' : 'Please fill out all mandatory fields';
        this.invalid = true;
        window.scrollTo(0, 0);
      }

      form.classList.toggle('was-validated', this.invalid);
    },
    async onSupplierChange() {
      // Get confirmation to reset po & items on change in supplier
      if (this.prevSupplier && this.$v.grn.purchaseOrder.$model) {
        const isConfirmed = window.confirm('This will reset po and item selections. Are you sure you want to do this?');

        if (isConfirmed) {
          this.$v.grn.purchaseOrder.$model = '';
          this.prevPurchaseOrder = null;
          this.$v.grn.items.$model = [];

          if (!this.$v.grn.supplier.$model) {
            return;
          }
        }
        else {
          this.$v.grn.supplier.$model = this.prevSupplier;
          return;
        }
      }

      if (!this.$v.grn.supplier.$model) {
        return;
      }

      this.purchaseOrders = (await purchaseOrders.getByParameters({
        supplier_id: this.$v.grn.supplier.$model.id,
      }));
      this.prevSupplier = this.$v.grn.supplier.$model;
    },
    async onPurchaseOrderChange() {
      // Get confirmation to reset items on change in po
      if (this.prevPurchaseOrder && this.$v.grn.items.$model.length) {
        const isConfirmed = window.confirm('This will reset item selections. Are you sure you want to do this?');

        if (isConfirmed) {
          this.$v.grn.items.$model = [];

          if (!this.$v.grn.purchaseOrder.$model) {
            return;
          }
        }
        else {
          this.$v.grn.purchaseOrder.$model = this.prevPurchaseOrder;
          return;
        }
      }

      if (!this.$v.grn.purchaseOrder.$model) {
        return;
      }

      const {item} = (await purchaseOrders.getById(this.$v.grn.purchaseOrder.$model.id, {
        params: {
          with: 'purchases.article',
        },
      }));
      this.articles = item.purchases.map(purchase => purchase.article);
      this.prevPurchaseOrder = this.$v.grn.purchaseOrder.$model;
    },

    addNewGRNItem(item) {
      this.showGRNItemForm = false;
      this.$v.grn.items.$model.push(item);
    },
    deleteGRNItem(deletedIndex) {
      const parsedDeletedIndex = parseInt(deletedIndex);
      this.$v.grn.items.$model = this.$v.grn.items.$model.filter((item, index) => index !== parsedDeletedIndex);
    },
    async nextSuppliers(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;
      const result = await suppliers.getByParameters(params);
      return result;
    },

    async nextPurchaseOrders(page, query) {
      const params = {
        column: 'name',
        direction: 'asc',
        page,
        supplier_id: this.$v.grn.supplier.$model.id,
      };

      if (query.length) params.query = query;
      const result = await purchaseOrders.getByParameters(params);
      return result;
    },
  },
  validations: {
    grn: {
      supplier: {
        required,
        type: Object,
      },
      purchaseOrder: {
        required,
        type: Object,
      },
      items: {
        required,
        type: Array,
      },
    },
  },
};
</script>

<style scoped>
p.lead {
  font-size: 1.5rem;
}

.delete-icon {
  color: #8B0000;
  font-size: 20px;
}

.delete-icon:hover {
  cursor: pointer;
}

.reduce-ul-height>>>ul {
  max-height: 240px;
}
</style>
