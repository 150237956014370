<template>
  <form
    data-test="form-add-grn-item"
    :class="{'was-validated': $v.$dirty}"
    class="custom-form"
    @submit.prevent="submit"
  >

    <div class="form-container">
      <!-- Article Dropdown -->
      <div class="form-group w-75">
        <label class="form-label mb-3">
          Article <span class="text-danger">*</span>
        </label>
        <autocomplete
          :list="{items: articles}"
          :customClass="{'is-invalid': $v.article.$error}"
          placeholder="Select Article"
          data-test="txt-article"
          label="name"
          local
          @input="newValue => selectArticle(newValue)"
        />
        <template v-if="$v.article.$dirty">
          <div v-if="!$v.article.required" class="invalid-feedback d-block">
            Article is required
          </div>
        </template>
      </div>
      <!-- Article Dropdown End-->

      <!-- Quantity Input -->
      <div class="form-group mt-3 w-75">

        <label class="form-label mb-3">
          Quantity <span class="text-danger">*</span>
        </label>
        <input
          v-model.number="$v.quantity.$model"
          type="number"
          step="0.01"
          data-test="txt-quantity"
          :class="{'is-invalid': $v.quantity.$error}"
          class="form-control"/>
        <template v-if="$v.quantity.$dirty">
          <div v-if="!$v.quantity.required" class="invalid-feedback d-block">
            Quantity is required
          </div>
          <div v-if="!$v.quantity.minValue" class="invalid-feedback d-block">
            Quantity must be more than 0
          </div>
        </template>
      </div>
      <!-- Quantity Input End -->

      <!-- Reason Dropdown -->
      <div class="form-group mt-3 w-75">

        <label class="form-label mb-3">
          Reason <span class="text-danger">*</span>
        </label>
        <select
          v-model="$v.reason.$model"
          :class="{'is-invalid': $v.reason.$error}"
          placeholder="Select"
          data-test="txt-reason"
          class="form-control custom-select">
          <option :value="null" disabled>Select reason</option>
          <option
            v-for="reason in GRNReasons"
            :key="reason"
            :value="reason">
            {{reason}}
          </option>
        </select>
        <template v-if="$v.reason.$dirty">
          <div v-if="!$v.reason.required" class="invalid-feedback d-block">
            Reason is required
          </div>
        </template>
      </div>
      <!-- Reason Dropdown -->
      <button
        data-test="btn-add-item"
        type="submit"
        class="btn btn-primary w-9 mt-4 mb-2">
        Add Item
      </button>

    </div>

  </form>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import GRNReasons from '@/assets/enums/GRNReasons';
import Autocomplete from '@/components/Autocomplete';
import minValue from 'vuelidate/lib/validators/minValue';

export default {
  name: 'GRNItemForm',
  components: {

    Autocomplete,
  },
  props: {
    articles: {
      type: Array,
      required,
    },
  },
  data() {
    return {
      GRNReasons,
      article: null,
      quantity: 0,
      reason: null,
    };
  },
  validations: {
    article: {
      required,
      type: Object,
    },
    quantity: {
      required,
      type: Number,
      minValue: minValue(0.01),
    },
    reason: {
      required,
      type: String,
    },
  },
  methods: {
    selectArticle(article) {
      this.$v.article.$model = article;
    },

    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit('submit', {
          article: this.article,
          quantity: this.quantity,
          reason: this.reason,
        });
      }
    },
  },
};
</script>

<style>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center
}
</style>
